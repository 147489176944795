const projects = [
  {
    name: 'Portfolio',
    link: 'https://github.com/AyonC123/Portfolio'
  },
  {
    name: 'CoffeBliss',
    link: 'https://github.com/AyonC123/Coffee-Brand'
  },
  {
    name: 'Empty',
    link: 'https://github.com/AyonC123'
  },
  {
    name: 'Empty',
    link: 'https://github.com/AyonC123'
  },
  {
    name: 'Empty',
    link: 'https://github.com/AyonC123'
  },
  {
    name: 'Empty',
    link: 'https://github.com/AyonC123'
  }
]

module.exports = projects